import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconBackArrow from "../../../CommonImages/icon-backArrow";
import { Skeleton } from "@material-ui/lab";
import { Button, Typography } from "@material-ui/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Translate } from "../../../utils/Translate";
import Routes from "../../../components/Router/Routes";
import VideoPlayer from "../../../hoc/VideoPlayerContainer";
import { usePlayListPlayer } from "../../../hooks/usePlayListPlayer";
import {
  logOnDemandTracking,
  resetStreamingLink,
} from "../../../store/actions/ondemand";
import "./PlayListPlayer.scss";
import "../../../theme/styles/video-js.css";
import { getTime, videoPlayerState } from "../../../utils/utilityfunctions";
import { map, findIndex } from "lodash";
import { usePlayListInfo } from "../../../hooks/usePlayListInfo";
import { postWorkoutResult } from "../../../store/actions/workout";
import { PlayListPlayerShimmer } from "./PlayListPlayer.shimmer";
import { GetUserSubscription } from "../../../store/actions/subscription";
import CustomDialog from "../../../components/Modal/Modal";
import Spinner from "../../../components/spinner/spinner";
import Close from "CommonImages/close";
import { IS_PLAYLIST_PLAYER } from "../../../utils/constants";

const config = require("../../../assets/config.json");
var moment = require("moment");
const PlayListPlayer = forwardRef((props, ref) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { playListInfo } = usePlayListInfo(props?.playlistId);
  const backToPlaylist = () => {
    navigate(Routes.userPlayList + "/" + props?.playlistId);
  };
  const { title, description } = playListInfo;
  const { loading } = props;
  const playlistItemClicked = useRef(null);
  const navigate = useNavigate();
  const { usePlayListPlayerDispatch, usePlayListState } = usePlayListPlayer(
    props?.playlistId
  );

  const {
    isSubscriptionLoading,
    hasPlayerAccess,
    playlistStreamingLink,
  } = usePlayListState();
  const playList = useSelector((state) => state.playList);
  const [playlistClasses, setPlaylistClasses] = useState([]);

  const [streamingUrl, setStreamingUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [playListTitle, setPlayListTitle] = useState("");
  const [playListDesc, setPlayListDesc] = useState("");
  const [playingClass, setPlayingClass] = useState("");
  const [selecteditemIndex, setSelectedItemIndex] = useState(0);
  const [ClassDetailsId, setClassDetailsId] = useState(null);
  const loopEnabled = JSON.parse(localStorage.getItem("isLoop"));
  const [isShimmer, setShimmer] = useState(false);
  const [isPageShimmer, setPageShimmer] = useState(true);
  const [isautostart, setAutoStart] = useState(true);
  const playingClassRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const classPlaying = useRef(false);
  const [firstTimeDataLoad, setFirstTimeDataLoad] = useState(true);
  const [thumbImageClickIndex, setThumbImageClickIndex] = useState(-1);
  let state = videoPlayerState();

  const [setState, setVideoState] = useState(state);
  const [playListData, setPlayListJWPlayer] = useState([]);
  const videoPlayerRef = useRef();
  const {
    classStarted,
    classClosed,
    fetchPlayListItemStreamURL,
  } = usePlayListPlayerDispatch();

  useEffect(() => {
    if (props?.playlistId) {
      if (!isSubscriptionLoading) getUserSubscription();
      fetchPlayListItemStreamURL(props?.playlistId);
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(resetStreamingLink());
    };
  }, []);

  const getUserSubscription = () => {
    dispatch(GetUserSubscription());
  };

  useEffect(() => {
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!isSubscriptionLoading && isSubscriptionLoading !== null) {
      if (hasPlayerAccess === true) {
        setVideoState({ ...setState, videoPlaying: true, loading: false });
      } else if (hasPlayerAccess === false) {
        if (config?.showUpgradeSubscriptionPopUp !== undefined) {
          setVideoState({ ...setState, showDialog: true, loading: false });
        } else {
          navigate("/subscribe");
        }
      }
    }
  }, [isSubscriptionLoading]);

  useEffect(() => {
    if (
      playList?.info?.list &&
      playList?.info?.list.length > 0 &&
      firstTimeDataLoad
    ) {
      const playablePlayListData = playList?.info?.list?.filter(
        (playListDetail) => playListDetail.isPlayable && playListDetail.isUnlocked
      );
      //setPlayingClass(playablePlayListData[0]);
      //setClassDetailsId({ id: playablePlayListData[0]?.tag });
      setPlayListTitle(title);
      setPlayListDesc(description);
      setPlaylistClasses(
        playablePlayListData.slice(0, playablePlayListData.length)
      );
      //setPageShimmer(false);
      //playingClassRef.current = playablePlayListData[0];
     // setThumbnailUrl(playablePlayListData[0]?.imageLink);
      setFirstTimeDataLoad(false);
    }
  }, [playList]);

  const postWorkoutResultsData = (userId, elapsedTime) => {
    const sClass = {
      classCommitmentTag: null,
      primaryGoal: 0,
      secondaryGoal: 0,
      skillLevel: 0,
      durationSeconds: elapsedTime,
      mood: null,
      completedDate: new Date(),
      tag: playingClass.tag,
      intensityLevel: 0,
      workoutName: playingClass.title,
      userWorkoutTag: playingClass.tag,
      isTimed: false,
      calorieBurn: 0,
      exercises: null,
      durationMinutes: Math.ceil(elapsedTime / 60),
      trackingResult: 7, // 9 use kiya hain useLiveStreamPlayer.js
      imageUrl: playingClass.imageLink,
      userId: userId,
      trailerLinkWeb: "",
      workoutDescription: "",
      creationDate: new Date(),
      keywords: "",
      classCategories: "",
      level: "",
      deviceId: "",
    };
    dispatch(postWorkoutResult(sClass));
  };
  const onDemandTrackingCall = (elapsedTime, userId) => {
    const selectedClass = {
      requestSource: 1,
      subscriptionTag: "",
      performDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      providerName: "",
      providerId: config.providerId,
      id: playingClass.tag,
      title: playingClass.title,
      actualDurationSecond: playingClass.duration,
      playedDurationSecond: elapsedTime,
      subscriptionStartDate: "",
      userId: userId,
      deviceId: "",
      ID: userId + "-" + playingClass.tag + "-" + moment().unix(),
    };
    dispatch(
      logOnDemandTracking(selectedClass, userId, elapsedTime, false) //isClubConnect
    );
  };

  const elapsedTimeLiftstateUp = (time) => {
    if (time > 0) {
      classPlaying.current = true;
    }
  };

  const handleItemSelect = (index) => {
    const element = document.getElementById(`play-${index}`);
    if (element !== null && element !== undefined) {
      element.scrollIntoView({
        block: "center",
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  const playListImageClick = (selectedClass, index) => {
    setThumbImageClickIndex(index);
    playlistItemClicked.current = selectedClass;
    setSelectedThumbImage(index);
  };
  const setSelectedThumbImage = (index) => {
    document
      .querySelector("div[id^=play-] >  div > span.sp-inblock > img")
      .setAttribute("class", "playlist-unselected player-video");

    let id = "#play-" + (index + 1);
    document
      .querySelector(id + "> div > span.sp-inblock > img")
      .setAttribute("class", "playlist-selected player-video");
  };
  const nextClassPlay = (props) => {
   
  };

  const getMediaScreenResolution = () => {
    const mediaQuery = window.matchMedia("(pointer: coarse)");
    return mediaQuery?.matches;
  };

  const trackAndLogClass = (islogging, istracking, elapsedTime = 0) => {
    //if (istracking && islogging) {
    if (elapsedTime > 0) {
      //need to do code for ondemand tracking code
      onDemandTrackingCall(elapsedTime, userId);
    }
    if (elapsedTime > 59) {
      postWorkoutResultsData(userId, elapsedTime);
    }
    //}
  };

  const selectedThumbVideo = (index, item) => {
    setThumbImageClickIndex(index);
    handleItemSelect(index + 1);
    const playablePlayListData = playList?.info?.list?.filter(
      (playListDetail) =>
        playListDetail.isPlayable && playListDetail.isUnlocked && playListDetail.tag === item.mediaid
    );
    setPlayingClass(playablePlayListData[0]);
    
  };

  const okButtonClick = () => {
    setVideoState({ ...setState, showDialog: false });
    if (config?.UpgradeRedirect != null) {
      window.open(config.UpgradeRedirect, "_blank");
      props.navigate(-1);
    }
  };

  useEffect(() => {
    let mappedPlaylist = [];
      if (
        playlistStreamingLink.length > 0 &&
        playList?.info?.list &&
        playList?.info?.list.length > 0
      ) {
        let playablePlayListData = playList?.info?.list.filter(
          (playListDetail) =>
            playListDetail.isPlayable && playListDetail.isUnlocked
        );
        mappedPlaylist = mapStreamingLinksWithPlaylist(
          playablePlayListData,
          playlistStreamingLink
        );
      }
    mappedPlaylist && mappedPlaylist.length > 0
      ? playlistItemClicked.current === null
        ? createJWPlayListData(mappedPlaylist)
        : createJWPlayListData(mappedPlaylist)
      : [];
  }, [playlistStreamingLink]);

  const mapStreamingLinksWithPlaylist = (playlistDetail, streamingLinks) => {
    let list = [];
    if (playlistDetail && playlistDetail.length > 0) {
      list = playlistDetail.map((element) => {
        const matchingElement = streamingLinks.find(
          (item) => item.tag === element.tag
        );
        if (matchingElement) {
          return {
            ...element,
            ...matchingElement,
          };
        } else {
          return element;
        }
      });
    }
    return list;
  };

  const createJWPlayListData = (playlistItems) => {
   setIsLoading(false);
    let playlistStreamingURLs = [];
    playlistItems &&
      playlistItems.forEach((item) => {
        const newItem = {
          file: item.streamingUrl,
          image: item.imageLink,
          title: item.title,
          mediaid: item.tag,
          tracks: item.tracks
        };
        playlistStreamingURLs.push(newItem);
      });
    setPlayListJWPlayer(playlistStreamingURLs);
    if (playlistStreamingURLs && playlistStreamingURLs.length > 0) {
      let videoState = {
        url: playlistStreamingURLs[0].file,
        imageLink: playlistStreamingURLs[0].image,
        alternateLink: playlistStreamingURLs[0].image,
        thumbnail: playlistStreamingURLs[0].image,
      };
      setVideoState({
        ...setState,
        thumbnail: playlistStreamingURLs[0].image,
        url: playlistStreamingURLs[0].file,
        video: videoState,
        eventId: playlistStreamingURLs[0].mediaid,
        classId: playlistStreamingURLs[0].mediaid,
      });
    }
    setIsLoading(true);
    setPageShimmer(false);
  };

  const checkIsPlayerPageLoad = () => {
      return (
        isPageShimmer == false &&
        isLoading &&
        playlistStreamingLink?.length > 0 &&
        playList?.info?.list &&
        playList?.info?.list.length > 0
      );
  }
 

  return (
    <div
      className={
        isShimmer
          ? "m-t-xs-5 play-list-info loading"
          : "m-t-xs-5 play-list-info "
      }
    >
      {playlistStreamingLink &&
      playlistStreamingLink?.length < 0 &&
      playList?.info?.list &&
      playList?.info?.list.length < 0 ? (
        <PlayListPlayerShimmer />
      ) : (
        <div className="play-list-info-details">
          <div className="play-list-info-heading">
            <div
              onClick={backToPlaylist}
              className="play-list-info-heading-icon"
            >
              <IconBackArrow
                id="play-list-info-back-arrow"
                color={config.primaryColor}
              />
            </div>
            <div className="play-list-info-heading-title">
              {loading ? (
                <Skeleton variant="rect" width={"100%"} height={50} />
              ) : (
                <span>{playListTitle}</span>
              )}
            </div>
            <div className="play-list-info-heading-controls">
              <div className="play-list-info-heading-text mt20">
                <div
                  className="rectangle-playerlist-crossicon"
                  onClick={backToPlaylist}
                >
                  <Close onClick={backToPlaylist} color={config.primaryColor} />
                </div>
              </div>
              <div className="play-list-info-heading-btn"></div>
            </div>
            <div className="play-list-info-description play-list-player">
              {playListDesc}
            </div>
          </div>
          <div
            className="p-t-player p-t-xs-16 info-container m-b-info-cont"
            id="playingClassRef"
          >
            {setState?.showDialog ? (
              <CustomDialog
                open={true}
                title={Translate({ id: "Subscription.UpgradeMembership" })}
                heading={Translate({ id: "Subscription.UpgradeMessage" })}
              >
                <div className="text-center padding16">
                  <Button
                    size="small"
                    type="submit"
                    color="secondary"
                    className="button btn-default"
                    variant="contained"
                    onClick={okButtonClick}
                    style={{ marginTop: "60px" }}
                  >
                    <Typography variant="h5">
                      {Translate({ id: "Subscription.Ok" })}
                    </Typography>
                  </Button>
                </div>
              </CustomDialog>
            ) : null}
            <div className="video-player play-list-video-player" id="playerRef">
              {isPageShimmer && !isLoading ? (
                <Spinner backColor="white" />
              ) : null}
              {checkIsPlayerPageLoad() ? (
                <VideoPlayer
                  reference={videoPlayerRef}
                  autostart={isautostart}
                  classStarted={classStarted}
                  classClosed={classClosed}
                  paused={true}
                  elapsedTimeLiftstateUp={elapsedTimeLiftstateUp}
                  trackAndLogClass={trackAndLogClass}
                  props={state}
                  videoProps={setState}
                  ClassDetails={ClassDetailsId}
                  nextClassPlay={nextClassPlay}
                  PlaylistPlayer={IS_PLAYLIST_PLAYER}
                  navigate={navigate}
                  location={location}
                  selectedThumbVideo={selectedThumbVideo}
                  playListData={playListData}
                  thumbImageClickIndex={thumbImageClickIndex}
                  setSelectedThumbImage={setSelectedThumbImage}
                />
              ) : (
                // <img
                //   className="video-wrapper"
                //   src={`${thumbnailUrl}`}
                //   alt="No-Video"
                // />
                <PlayListPlayerShimmer />
              )}
            </div>
            <div
              className="class-side-info right-bar"
              id="playingClassDetailRef"
            >
              <div className="play-list-item  no-drag">
                {playlistStreamingLink?.length > 0 &&
                  playList?.info?.list &&
                  playList?.info?.list.length > 0 &&
                  map(playlistClasses, (item, index) => (
                    <div key={Math.random()} id={`play-${index + 1}`}>
                      <div
                        className="play-list-item-image player-item"
                        onClick={
                          getMediaScreenResolution()
                            ? () => {
                                playListImageClick(item, index);
                                handleItemSelect(index + 1);
                              }
                            : null
                        }
                      >
                        <span
                          className="sp-inblock"
                          onClick={
                            !getMediaScreenResolution()
                              ? () => {
                                  playListImageClick(item, index);
                                  handleItemSelect(index + 1);
                                }
                              : null
                          }
                        >
                          <img
                            className={`${
                              thumbImageClickIndex === index
                                ? "playlist-selected"
                                : "playlist-unselected"
                            } player-video`}
                            alt={thumbnailUrl}
                            src={item.imageLink}
                          />
                        </span>
                        <span className="left-title">
                          <div className="play-list-item-class-info-heading">
                            {item?.title}
                          </div>
                          <div className="play-list-item-class-info-time text-gray duration-text">
                            {getTime(item?.duration)}
                          </div>
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

PlayListPlayer.propTypes = {};

export { PlayListPlayer };
