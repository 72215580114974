import React from "react";
import { createRoot } from 'react-dom/client';
// import ReactDOM from "react-dom";
import "./theme/styles/index.scss";
import App from "./AppWrapper";
import registerServiceWorker from "./registerServiceWorker";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
// import locale_en from "react-intl/locale-data/en";
// import locale_de from "react-intl/locale-data/de";
import { v4 as uuidv4 } from 'uuid';
import { flattenMessages } from "./utils/flattenMessages";
import onDemandClassesReducer from "./store/reducers/ondemand";
import authReducer from "./store/reducers/auth";
import workoutReducer from "./store/reducers/workout";
import subscriptionReducer from "./store/reducers/subscription";
import webPlayerReducer from "./store/reducers/webPlayer";
import clientSettingsReducer from "./store/reducers/clientSettings";
import channelsReducer from "./store/reducers/channels";
import filter from "./store/reducers/filter";
import filterOnDemand from "./store/reducers/filterOnDemand";
import dynamicDeeplink from "./store/reducers/dynamicDeeplink";
import moreClasses from "./store/reducers/moreClasses";
import playList from "./store/reducers/playList";
import ratingReviews from "./store/reducers/ratingReview";
import globalSearch from "./store/reducers/globalSearch";
import subscriptionBundle from "./store/reducers/subscriptionBundle";
import siteConfigurationReducer from "./store/reducers/siteConfig";
import {isEmptyOrNullString, isPseudoSso} from "./utils/utilityfunctions";
import tenantConfig from "./store/reducers/tenantConfig";

import {
  GetTranslationData,
  checkLocalStorage,
  GetConfigData,
} from "./store/actions/ClientSettings";
import UnsupportedBrowser from "./components/UnsupportedBrowser";
import { BrowserRouter } from "react-router-dom";
const addLocaleData = require("@formatjs/intl-pluralrules/polyfill");
const locale_de = require("@formatjs/intl-pluralrules/locale-data/de");
const locale_en = require("@formatjs/intl-pluralrules/locale-data/en");
const config = require("./assets/config.json");

registerServiceWorker();

const URL =
  window.location.search && window.location.search !== ""
    ? window.location.search
    : sessionStorage.getItem("embededConfig");

let embededConfig = {};
if (URL && URL !== "" && URL.includes("runtime=embedded")) {
  sessionStorage.setItem("embededConfig", URL);
  let queryParamsArray = URL.slice(1)
    .split("&")
    .map((str) => str.split("="));
  for (let i = queryParamsArray.length; i--; ) {
    embededConfig[queryParamsArray[i][0]] = queryParamsArray[i][1];
  }

  if (embededConfig["lang"]) {
    config["language"] = embededConfig["lang"];
  }

  if (!embededConfig["token"] && isPseudoSso() && isEmptyOrNullString(embededConfig.userId)) {
    const existingUserId = localStorage.getItem("externalUserId");
    let userId = existingUserId ?? uuidv4();
    embededConfig["userId"] = userId;
    localStorage.setItem("externalUserId", embededConfig["userId"]);
    localStorage.setItem("ExternalUserId", embededConfig["userId"]);
  }
}
// addLocaleData([locale_en, locale_de]);

checkLocalStorage();
var clientData = GetConfigData();
const language = clientData.language;
const message = GetTranslationData(clientData.name, language);
var title = clientData.appTitle;
document.getElementById("title").innerHTML = title;

var style = document.createElement("style");
style.innerHTML = `.vjs-play-progress , .vjs-volume-level    {background-color: ${localStorage.getItem(
  "clientcolor"
)} !important;}`;
style.innerHTML = `.dynamiclinks   {color: ${localStorage.getItem(
  "clientcolor"
)} !important;}`;

/**
 * Rajneesh Raghav
 * 12th July 2020
 * Setting Css Varialbe's values.
 */
document.documentElement.style.setProperty(
  "--main-primary-color",
  config.primaryColor
);
document.documentElement.style.setProperty(
  "--main-secondary-color",
  config.secondaryColor
);
document.documentElement.style.setProperty(
  "--hover-secondary-color",
  config.secondaryColor
);
document.documentElement.style.setProperty("--hover-color", config.accentColor);
document.documentElement.style.setProperty(
  "--bg-theme-color",
  config.backgroundTheme
);
document.documentElement.style.setProperty("--bar-color", config.barColor);
document.documentElement.style.setProperty(
  "--footer-color",
  config.footerColor
);
document.documentElement.style.setProperty(
  "--icon-color",
  config.secondaryAccentColor
);
document.documentElement.style.setProperty(
  "--lipstick",
  config.newLabelBgColor
);

// eslint-disable-next-line no-unused-vars
var ref = document.querySelector("script");
ref = ref?.parentNode?.insertBefore(style, ref);
//ref.parentNode.insertBefore(style, ref);

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== undefined
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose
    : null || compose;

const rootReducer = combineReducers({
  // orm:schema.reducer(),
  onDemand: onDemandClassesReducer,
  workout: workoutReducer,
  auth: authReducer,
  subscription: subscriptionReducer,
  webPlayerEvents: webPlayerReducer,
  client: clientSettingsReducer,
  channels: channelsReducer,
  filter: filter,
  moreClasses: moreClasses,
  filterOnDemand: filterOnDemand,
  dynamicDeeplink: dynamicDeeplink,
  playList: playList,
  ratingReviews: ratingReviews,
  globalSearch: globalSearch,
  subscriptionBundle: subscriptionBundle,
  siteConfiguration: siteConfigurationReducer,
  tenantConfig: tenantConfig,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);


/**
 * nikhil gupta
 * 22th june 2020
 * check for internet explorer
 * edge without chromium (Edge/)
 */
var ua = window.navigator.userAgent;
var isIE = /MSIE|Trident|Edge\//.test(ua);

const Index = ()=> {
  const appLoadStartTime = new Date().getTime();
  return (
    <React.Fragment>
      {isIE ? <UnsupportedBrowser /> : (
      <Provider store={store}>
        <IntlProvider
          locale={"en"}
          messages={flattenMessages(message[language])}
          defaultLocale="en"
          defaultMessages={flattenMessages(message["en"])}
          onError={()=>{}}
        >
          <BrowserRouter>
            <App appLoadStartTime={appLoadStartTime} embededConfig={embededConfig} />
          </BrowserRouter>
        </IntlProvider>
      </Provider>
      )}
    </React.Fragment>

)};
const root = createRoot(document.getElementById("root"));
root.render(<Index/>);