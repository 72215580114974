export const authenticatePlatformUserAPI = "/oauth/authenticateplatformuser";
export const thirdPartyUserOnWexerApi = "oauth/login";
export const creatThirdParyUserOnWexerAPI = "/oauth/signup";
export const LoginAPI = "/oauth/login";

//export const authenticatePlatformUserAPI = "/oauth/authenticatewebuser/";
export const consentAPI = "/api/v2/consent";
export const userConsentAPI = "/api/v2/users/consent";
export const saveUserConsentAPI = "/api/v2/users/consent";
export const getUserDetailAPI = "/api/v3/users";
export const sendContactUsMail = "/api/v1/ContactUs";
export const updateUserDetails = "/api/v3/users";
export const forgetPassword = "api/v2/forgotpassword";
export const resetPassword = "api/v2/resetpassword";

export const onDemandDeepLinkClassAPI = "/api/v2/virtualclasses";
export const onDemandSearchMetadataAPI = "/v4/users/ondemand/metadata";
export const onDemandCollectionAPI = "v4/collections/getlist"; //body to be added whilist changing method

export const onDemandNewSearchAPI = "v5/virtualclass/search";
export const intensityAPI = "/api/v2/intensitycodes";
export const logOnDemandTrackingAPI = "/api/v2/ondemandtracking";
export const logWorkoutResult = "/api/v4/users/workoutresults";
export const getUserRecentActivity = "/v4/users/getrecentactivities";

//liveStream --> adding trailer link to live stream videos on my activity
export const putUserRecentActivity = "/api/v3/users/workoutresults";

export const setClassFavouritePost = "/api/v2/users/preferredvirtualclass";
export const setClassFavouriteGet = "/v4/users/getfavorites";

export const getSubscriptionProductAPI = "/api/v2/products";
export const getUserSubscriptionAPI = "/v4/users/subscriptioncheck";
export const getUserSubscriptionDetailsAPI = "/v4/users/subscriptiondetails";
export const FreeTrailAPI = "/api/v1/payments/trial";
export const purchaseSubscriptionAPI = "/api/v1/payments/subscription";
export const cancelUserSubscriptionAPI = "/v4/users/subscriptionsdelete";
export const resumeUserSubscriptionAPI = "/api/v1/payments/subscription";
export const getFreeAccessSubscription = "api/v2/subscription";
export const verifyFreeAccessCode = "api/v1/verify/freeaccess/";
export const getSubscriptionPortalLinkAPI = "/v5/users/customerportalurl";

export const createPlatformUserAPI = "/api/v1/createplatformuser";
export const createUserWithUserIdAPI = "/api/v1/identity/login";
export const clubLiveConnectAPI = "/api/v1/ondemand/connect/";

export const clubLiveConnectAPIFortenantAsProvider = "api/v1/connect/events";

export const collectionByTagAPI = "ondemand/v1/collections/";
export const getTenantConfigAPI = "/api/v1/tenants/";

export const confirmationEmail = "/api/v1/campaign/sendemail/";

/// Channels
//RR - Same api can be used with channel tag as well, to obtaine titles of that that channel Ex -
//{{BaseUri}}/api/v1/channels/1c600c92-2afb-420c-aa49-eb2c3e03a283"

export const getAllChannelsClasses = "v4/channels/gettitles";
export const getAllChannels = "/api/v2/channels/";

//RR - API to retrive a single class detail along with Channel information-
//api/v1/channels/titles/{titleId}"
export const getDeeplinkedChannelClass = "v4/title";

/*COTD */
export const getCOTDHomePageData = "/v4/collections/getcotd";

export const getCategoryClassesUnderOnDemandClass = "/v4/virtualclass/";

//nikhil
//fetch the FLL events from the PaperProps
export const fetchFLLEvents = "/api/v1/connect/events";
export const fetchFLLEventsV5 = "/v5/events";

export const fetchFLLAutoPublishVideos = "/api/v2/ondemand/connect/";
export const fetchEventDetailsV5 = "/v5/event/access";
export const fetchEventDataV6 = "/v6/event/recordedclasses";

export const playbackURL = "v4/users/getstreamingurl";

export const fetchPlayList = "v5/playlist/getplaylists";
export const createPlayList = "v4/playlist/Create";
export const updatePlayList = "v4/playlist/update";
export const deletePlayList = "v4/playlist/delete";
export const addItemToPlayList = "v4/playlist/additem";
export const deleteItemToPlayList = "v4/playlist/deleteitem";
export const fetchPlayListInfo = "v5/playlist/getitems";
export const reorderPlayListInfo = "v4/playlist/reorderitems";
export const accessPlayListItems = "v4/playlist/accessitems";
//Review and Rating
export const fetchUserRating = "/v5/getratingandreview"
export const updateUserRating = "/v4/ratingreview"
// subscription Bundle Api
export const getSubscriptionBundleDetailAPI = "/v4/subspkg/details";
export const getAllSubscriptionBundleDetailAPI = "/v4/users/subspkg/mysubscriptions";
export const getStreamingUrlAPI = "/v4/users/getstreamingurl";
export const getSubscriptionPackageAPI = "/v4/subspkg/subscriptions";
export const getSubscriptionPackageForTitleAPI = "/v4/subspkg/title/subscriptions";
export const getTenantEntitlementAPI = "/v4/tenant/getconfigs";

export const setPasswordTokenValidation =
  "/v4/user/setpassword/tokenvalidation";
export const setPassword = "/v4/user/setpassword";
export const resendSetPassword = "/v4/user/setpassword/resend";

//Site Config Links
export const fetchSiteConfig = "/v4/tenant/siteconfig/getconfig";

//Document Library
export const downloadDocument = "/v4/document/download";
export const collectionList = "/v4/collections/getlist";
export const collectionListTitlesDetails = "/v4/collections/gettitles";

export const fetchPlayListStreamURL = "/v4/playlist/getstreamingurl";