import React from "react";
import "../../components/PlayerAndCarousel/Workout/Workout.css";
import CountdownButton from "./countdownButton";
import { getNextOccurenceDate, getTimetoStart } from '../../utils/LivestreamTime';
import { getPosterSize } from '../../utils/utilityfunctions';
import Routes from "../../components/Router/Routes"
import { Translate } from "../../utils/Translate";
import { useNavigate } from "react-router";
import { LockIcon } from "CommonImages/lock-icon";


function LiveStreamCard(props) {
  const navigate = useNavigate();
  const openPlayer = () => {
    navigate(`${Routes.liveSchedulePlayerRoute}${props.id}`,
          {state: {
            cardDetails:{activeTime: props.activeTime,
            creationDate: props.creationDate,
            description: props.description,
            duration: props.duration,
            eventStatus: props.eventStatus,
            id: props.id,
            level: props.level,
            nextOccurence: props.nextOccurence,
            thumbnail: props.thumbnail,
            title: props.title,
            trailerLinkWeb: props.trailerLinkWeb,
            //url: props.url,
            videoId:props.videoId,
            durationInSec:props.durationInSec,
            type: props.type
          }}
        });
  }
  const showLockIcon = () => {
    if (props.isUnlocked === true) {
      return false;
    }
    return true;
  };
  const cardType = "channel-page-card"
  const collectionType ="live"
  return (
    // <Grid container item className="">
    //   <div className="workoutPlayer">
    //     <video
    //       style={{ width: "100%" }}
    //       preload="none"
    //       loop
    //       playsInline
    //       className="Trailer thumbnail"
    //       poster={props.thumbnail}
    //       // onClick={openPlayer}
    //     //   onMouseOver={(e) => this.startVideo(e.target)}
    //     //   onMouseOut={e => e.target.pause()}
    //     >
    //       {/* <source
    //         src={props.trailerLinkMobile
    //           ? props.trailerLinkMobile
    //           : props.trailerLinkWeb
    //             ? props.trailerLinkWeb
    //             : props.url
    //         }
    //         type={props.trailerLinkMobile || props.trailerLinkWeb
    //           ? "video/mp4"
    //           : "application/x-mpegURL"
    //         }
    //       /> */}
    //     </video>
    //     <div className="workout-info makeTextClickable" onClick={openPlayer}>
    //       <Typography variant="subtitle2" className="workout-day">
    //         {getNextOccurenceDate(props.eventStatus, props.activeTime, props.nextOccurence)}
    //       </Typography>
    //       <Typography variant="h2" className="m-t-5 m-t-xs-5">
    //         {getTimetoStart(props.nextOccurence)}
    //       </Typography>
    //       <p className="workout-desc">
    //         {props.title}
    //       </p>
    //       {/* <CountdownButton startTime={props.activeTime} status={props.eventStatus} /> */}
    //       <CountdownButton startTime={props.activeTime} status={props.eventStatus} clickHandler={openPlayer} />

    //     </div>
    //   </div>
    // </Grid>
  //     <div>
  //     <div className={` ${cardType}`} onClick={()=>handleClick(index,item.tag,item)}><div className={`cardPos comman`} >
  //         <div class={`imageHolder ${cardType !== 'cotd-card' ? collectionType !== 'featured' ? "full-overlay":'' :"cotd-overlay"}`} style={{backgroundImage:`url(${item.imageLink ?? item.title[0].imageLink ?? item.pictures.sizes[0].link})`}}  >
  //             <div class="contentTop">
  //                 <div class="timeholder">
  //                {collectionType ==="live" && <span color="white" class="live-title m-b-8">{(item.className?? item.title[0].className??item.title).substring(0,20)}</span> }
  //                {collectionType !=="featured" && <span color="white" class={`classname ${cardType === 'cotd-card ' ? "cotd-date":"m-b-5"}`}>{cotdDate(item.schedule?.availableDate ?? item.displayDate)}</span> }
  //                {collectionType ==="live" &&  <span color="white" class="live-time">{getTimetoStart(item.schedule?.availableDate )}</span>}

  //                 </div>
  //             </div>
  //         </div>
  //     </div>
  //     </div>
  // {collectionType !=="live" && <div class="card-footer">
  //     <div color="white" style={{color:'black'}} class="time">{(item.className?? item.title[0].className??item.title)}</div>
  //         <div className="d-flex">
  //             <span color="white" style={{color:'grey'}} spacing="xl" class="font-caption">{(item.durationSecond ?? item.title[0].durationSecond)
  //                             ? Math.floor((item.durationSecond ?? item.title[0].durationSecond) / 60)
  //                             : 55} mins</span>
  //             <span color="white" spacing="s" style={{color:'grey'}} class="font-caption discIcon"> {getCardsDate(item.schedule?.availableDate ?? item.displayDate)}</span>
  //             <span color="white" spacing="s" style={{color:'grey'}} class="font-caption discIcon"> { item.level}</span></div>
  //         </div>}
  // </div>
  <div data-testid={"cardClick"} className={` ${cardType} makeTextClickable`} onClick={openPlayer}><div className={`cardPos comman`} >
                        <div className={`imageHolder ${cardType !== 'cotd-card'  ? "full-dark-overlay":"cotd-overlay"}`} style={{backgroundImage:`url(${props.thumbnail}?width=${getPosterSize(cardType).cardWidth})`}}  >
                            <div className="contentTop">
                                <div className="timeholder">
                               {collectionType ==="live" && <div className="d-flex">
                                <span color="white" class="live-title m-b-8 live-title-truncate">{props.title}</span>
                                {props.type !== "VOD" && <span className="live-label livecard-label m-b-8">{Translate({ id: "liveStream.Live" })}</span> }
                                </div> }
                                {collectionType !=="featured" && <span color="white" class={`classname ${cardType === 'cotd-card' ? "cotd-date":"m-b-5 "}`}>{getNextOccurenceDate(props.eventStatus, props.activeTime, props.nextOccurence)}</span> }
                               {collectionType ==="live" &&  <span color="white" class="live-time">{getTimetoStart(props.nextOccurence, props.activeTime)}</span>}
                                {showLockIcon() && (
                                <LockIcon color={"#fff"} classes={"livestream-lock-icon"} />
                                )}
                                </div>
                            </div>
                          </div>
                          {collectionType ==="live" && <CountdownButton startTime={props.activeTime} occurenceDate={props.nextOccurence} isStreaming={props.eventStatus} onClick={openPlayer} />}
                    </div>
                    </div>
  )
}

export default LiveStreamCard
